export class Profil {

    public long: number // Show full options'
    
    public camperlength: number
    public camperheight: number
    public camperwidth: number
    public tuev: Date
    public gas: Date
    public oel: number
    
    public plate: string

    public max_weight: number=+0
    public grundgewicht: number=+0
    public campertype: string
    public description: string

    public zubehoer=+0
    public personen=+0
    public lebensmittel=+0
    public wasser=+0
    public gepaeck=+0  

    public gesamtgewicht=+0  

    public camperbild = "camper"

    public firststart = true;
    
    public fillFromJSON(data): Profil {
        let p = new Profil();

        p.camperlength = +data['camperlength'];
        p.camperheight = +data['camperheight'];
        p.camperwidth = +data['camperwidth'];
        p.tuev = data['tuev'];
        p.gas = data['gas'];
        p.oel = data['oel'];
        p.plate = data['plate'];
        p.max_weight = +data['max_weight'];
        p.grundgewicht = +data['grundgewicht'];
        p.campertype = data['campertype'];
        p.description = data['description'];

        p.zubehoer = data['zubehoer'];
        p.personen = data['personen'];
        p.lebensmittel = +data['lebensmittel'];
        p.wasser = +data['wasser'];
        p.gepaeck = data['gepaeck'];

        p.gesamtgewicht = data['gesamtgewicht'];
        p.firststart = data['firststart'];

        p.camperbild = data['camperbild'];

        return p;
    }

}