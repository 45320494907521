import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { Profil } from './models/profil';
import { globals } from './models/globals';

// HTTP
import '@capacitor-community/http';
import { Plugins } from '@capacitor/core';


//const { LocalNotifications } = Plugins;
/*
const doGet = async (id) => {
  // Destructure as close to usage as possible for web plugin to work correctly
  // when running in the browser
  const { Http } = Plugins;

  return await Http.request({
    method: 'GET',
    url: 'http://camper.objectix.de:8082/Data/' + id,
    headers: {
      'X-Fake-Header': 'Max was here'
    },
    params: {
      'size': 'XL'
    }
  });
};

// Example of a POST request. Note: data
// can be passed as a raw JS Object (must be JSON serializable)
const doPost = async (uuid, listen) => {
  const { Http } = Plugins;

  const ret = await Http.request({
    method: 'POST',
    url: 'http://camper.objectix.de:8082/Data',
    headers: {
      'X-Fake-Header': 'Max was here',
      'Content-Type': 'application/json'
    },
    data: {
      data: listen,
      name: uuid
    }
  });
}
*/

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent implements OnInit {
  
  version
  
  public selectedIndex = 0
  public infos: any[]
  public checklisten: any[] = []
  public checklistennamen: any[] = []

  public appPages = []
  public last;
  profil: Profil = new Profil()
  

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private router: Router
  ) {
    this.initializeApp();
    
  }


  initializeApp() {

    console.log('initializeApp')
    this.storage.get("state" + globals.schema_version).then((states) => {
      if (states == null) {
        this.storeStateFile("camper");

        this.platform.ready().then(() => {
          this.statusBar.styleDefault();
          this.splashScreen.hide();
          this.listenAdmin();

        });

      } else {
        this.last = states['last']
        //this.loadInitial();    
      }

      this.version = globals.version

    });

  }



  ngOnInit() {
    console.log('ngOnInit')
    const path = window.location.pathname.split('folder/')[1];

    this.loadCurrentCheckliste().then(() => {
      if (path !== undefined) {
        this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
      }
    });
  }


  storeStateFile(last) {
    console.log('storeStateFile ' + last)
    let states = { "last": last };

    this.storage.set("state" + globals.schema_version, states).then((_) => {
      this.storage.get("state" + globals.schema_version).then((_) => {
      }).catch((e) => {
        console.log(e);
      });
    });
  }


  doRefresh($event) {
    console.log('doRefresh')

    this.selectedIndex = 0;
    this.infos = [];
    this.checklisten = [];
    this.checklistennamen = [];

    this.appPages = []
    this.last;
    this.profil = new Profil()

    this.loadCurrentCheckliste().then((_) => {
      $event.target.complete();
    });

  }

  

  loadCurrentCheckliste(): Promise<void | String> {
    console.log('loadCurrentCheckliste')

    return this.storage.get("checkliste" + globals.schema_version).then((listen) => {

      for (let liste of listen) {

        let entry = {
          title: liste[0].title,
          url: '/' + liste[0].path,
          icon: liste[0].icon,
          badge: liste[0].badge
        }
        this.appPages.push(entry)
      }

      this.storage.get("profil").then((result) => {

        if (result !== null) {

          this.profil = new Profil().fillFromJSON(JSON.parse(result))

          const tsNOW = (new Date().valueOf())
          const tsTUEV = (new Date(this.profil.tuev).valueOf())
          const tsGAS = (new Date(this.profil.gas).valueOf())

          const tuevtage = Math.round((tsTUEV - tsNOW) / 1000 / 60 / 60 / 24)
          const gastage = Math.round((tsGAS - tsNOW) / 1000 / 60 / 60 / 24)
          let add: string = ""

          for (let p of this.appPages) {

            if (p.title === "Gewicht") {

              if (this.profil.gesamtgewicht > this.profil.max_weight) {
                p.badge = 'danger';
              } else if (this.profil.gesamtgewicht > this.profil.max_weight * .95) {
                p.badge = 'warning';
              } else {
                p.badge = 'success';
              }
            }

            if (this.profil.plate !== "" && p.title === "Abmessungen") {
              if (tuevtage < 100) {
                add += "(T) "
              }
              if (gastage < 100) {
                add += "(G) "
              }
              if (this.profil.plate) {
                p.title = add + this.profil.plate
              } else {
                p.title = add + "Daten"
              }
            }

            if (p.title === "Gewicht") {

              if (this.profil.gesamtgewicht > this.profil.max_weight) {
                p.badge = 'danger';
              } else if (this.profil.gesamtgewicht > this.profil.max_weight * .95) {
                p.badge = 'warning';
              } else {
                p.badge = 'success';
              }
            }

            if (this.profil.plate !== "" && this.profil.plate !== null && p.title === "Abmessungen") {
              if (tuevtage < 100) {
                add += "(T) "
              }
              if (gastage < 100) {
                add += "(G) "
              }
              p.title = add + this.profil.plate
            
            }

            if (p.title === "Profil") {

              if (this.profil.tuev !== null && this.profil.gas !== null && this.profil.plate !== null  && this.profil.plate !== '') {
                p.badge = 'success';
              } else {
                p.badge = 'danger';
              }

            }

          }
        }
      });

    });

  }


  loadInitial(initialListName) {
    console.log('loadInitial ' + initialListName)

    this.storage.get("checklisten" + globals.schema_version).then((liste) => {
      for (let cl of liste) {
        let x = JSON.parse(cl);
        this.checklistennamen.push(x.listenname);
        this.checklisten.push(x);
      }

      let checkliste;
      for (let cl of this.checklisten) {
        if (cl.listenname === initialListName) {
          checkliste = cl.listen
          this.storage.set("checkliste" + globals.schema_version, checkliste).then((_) => {
            console.log("List set to " + initialListName)
          });

          this.storeStateFile(initialListName);
        }
      }
    });

  }

  navigate(target) {
    console.log('navigate ' + target)

    this.router.navigateByUrl('/' + target);
  }

  setCheckliste(listenname) {
    console.log('setCheckliste ' + listenname)

    let checkliste;
    for (let cl of this.checklisten) {
      if (cl.listenname === listenname) {
        checkliste = cl.listen
      }
    }

    this.storage.set("checkliste" + globals.schema_version, checkliste).then((_) => {
      console.log("List set to " + listenname)
    });

    this.storeStateFile(listenname);
  }

  listenAdmin() {
    console.log('listenAdmin')

    let alleChecklisten: string[] = [];

    let listenset: string[] = [];

    let checkliste: string[] = [];
    checkliste['fahrzeug'] = [{ 'state': 'checked', 'visible': true, 'path': 'folder/Fahrzeug', 'title': 'Fahrzeug', 'icon': 'car', 'info': 'Der Camper als technisches Gerät muß bestimmten Anforderungen genügen! Alles gecheckt?', 'completed': 'prima, fahrbereit!' },
    [
      { 'state': '', 'visible': true, 'title': 'TÜV', 'description': 'Prüftermin in der Zukunft?' },
      { 'state': '', 'visible': true, 'title': 'Gas', 'description': 'Prüftermin in der Zukunft?' },
      { 'state': '', 'visible': true, 'title': 'Ölstand', 'description': 'Geprüft?' },
      { 'state': '', 'visible': true, 'title': 'Reifen', 'description': 'Druck und Profil geprüft! Winterreifen?' },
      { 'state': '', 'visible': true, 'title': 'Feuerlöscher', 'description': 'Vorhanden, geprüft und Ort bekannt?' },
      { 'state': '', 'visible': true, 'title': 'Erste-Hilfe-Kasten', 'description': 'Vorhanden, aktuell und Ort bekannt?' },
      { 'state': '', 'visible': true, 'title': 'Warndreieck', 'description': 'Druck und Profil geprüft! Winterreifen?' },
      { 'state': '', 'visible': true, 'title': 'Wischwasser', 'description': 'Geprüft?' }
    ]];
    listenset.push(checkliste['fahrzeug']);

    checkliste['reiseantritt'] = [{
      'state': '', 'visible': true, 'title': 'Reiseantritt', 'path': 'folder/Reiseantritt', 'icon': 'checkmark-circle',
      'info': 'Es geht los auf grosse Fahrt, alles dabei?', 'completed': 'los geht\'s'
    },
    [
      { 'state': '', 'visible': true, 'title': 'Strom', 'description': 'Kabel (und Adapter) verstaut?' },
      { 'state': '', 'visible': true, 'title': 'Wasser', 'description': 'Schlauch eingepackt und gebunkert nach Bedarf?' },
      { 'state': '', 'visible': true, 'title': 'Gas', 'description': 'gesichert und Füllstand kontrolliert?' },
      { 'state': '', 'visible': true, 'title': 'Grauwasser', 'description': 'geleert?' },
      { 'state': '', 'visible': true, 'title': 'Auffahrkeile', 'description': 'verstaut und gesichert?' },
      { 'state': '', 'visible': true, 'title': 'Gepäck', 'description': 'verstaut und gesichert?' },
      { 'state': '', 'visible': true, 'title': 'Kleingeld', 'description': 'für Automaten (Strom, etc.)?' },
    ]];
    listenset.push(checkliste['reiseantritt']);

    checkliste['abfahrt'] = [{
      'state': '', 'visible': true, 'title': 'Abfahrt', 'path': 'folder/Abfahrt', 'icon': 'rocket',
      'info': 'Alles, was vor der Abfahrt zu beachten ist', 'completed': 'gute Fahrt'
    },
    [
      { 'state': '', 'visible': true, 'title': 'Landstrom', 'description': 'abgesteckt und Kabel verstaut?' },
      { 'state': '', 'visible': true, 'title': 'Gas', 'description': 'gesichert und Füllstand kontrolliert?' },
      { 'state': '', 'visible': true, 'title': 'Wasser', 'description': 'gefüllt nach Bedarf?' },
      { 'state': '', 'visible': true, 'title': 'Grauwasser', 'description': 'geleert?' },
      { 'state': '', 'visible': true, 'title': 'Satellitenantenne', 'description': 'eingefahren und kontrolliert?' },
      { 'state': '', 'visible': true, 'title': 'Fenster', 'description': 'geschlossen und verriegelt?' },
      { 'state': '', 'visible': true, 'title': 'Anbauteile', 'description': 'Wäscheständer, Leinen, etc.?' },
      { 'state': '', 'visible': true, 'title': 'Schränke/Schubladen', 'description': 'geschlossen und verriegelt?' },
      { 'state': '', 'visible': true, 'title': 'Auffahrkeile', 'description': 'verstaut und gesichert?' },
      { 'state': '', 'visible': true, 'title': 'Gepäck', 'description': 'verstaut und gesichert?' },
      { 'state': '', 'visible': true, 'title': 'Kleingeld', 'description': 'für Automaten (Strom, etc.)?' },
    ]];
    listenset.push(checkliste['abfahrt']);

    checkliste['ankunft'] = [{ 'state': '', 'visible': true, 'title': 'Ankunft', 'path': 'folder/Ankunft', 'icon': 'disc', 'info': 'Alles, was am Zielort zu tun ist', 'completed': 'schöne Zeit!' }, 
    [
      { 'state': '', 'visible': true, 'title': 'Landstrom', 'description': 'verbunden' }, 
      { 'state': '', 'visible': true, 'title': 'Auffahrkeile', 'description': 'Fahrzeug ausgerichtet' }, 
      { 'state': '', 'visible': true, 'title': 'Gas', 'description': 'gesichert und Füllstand kontrolliert' }]];
    listenset.push(checkliste['ankunft']);

    checkliste['rueckkehr'] = [{ 'state': '', 'visible': true, 'title': 'Rückkehr', 'icon': 'golf', 'path': 'folder/Rückkehr', 'info': 'Wieder zu Hause? Längere Pause!', 'completed': 'welcome home!' }, 
    [
      { 'state': '', 'visible': true, 'title': 'Landstrom', 'description': 'verbunden?' }, 
      { 'state': '', 'visible': true, 'title': 'Gas', 'description': 'gesichert und Füllstand kontrolliert?' }, 
      { 'state': '', 'visible': true, 'title': 'Wasser', 'description': 'geschützt?' },
      { 'state': '', 'visible': true, 'title': 'Grauwasser', 'description': 'geleert?' },
      { 'state': '', 'visible': true, 'title': 'Heizung', 'description': 'Innenraum temperieren (10°C)?' },
      { 'state': '', 'visible': true, 'title': 'Kühlschrank', 'description': 'leer und gereinigt?' }, 
      { 'state': '', 'visible': true, 'title': 'Bad', 'description': 'leer und gereinigt?' }, 
      { 'state': '', 'visible': true, 'title': 'Toilette', 'description': 'geleert und frisch befüllt' }
    ]];
    listenset.push(checkliste['rueckkehr']);

    checkliste['rueckkehrabfahrbereit'] = [{ 'state': '', 'visible': true, 'title': 'Rückkehr (Abfahrbereit)', 'icon': 'play-circle', 'path': 'folder/Rückkehr', 'info': 'Wieder zu Hause, aber jederzeit startbereit!', 'completed': 'welcome home!' }, 
      [
      { 'state': '', 'visible': true, 'title': 'Kraftstoff', 'description': 'vollgetankt?' },
      { 'state': '', 'visible': true, 'title': 'Wischwasser', 'description': 'aufgefüllt (mit Frstschutz)?' },
      { 'state': '', 'visible': true, 'title': 'Landstrom', 'description': 'verbunden?' }, 
      { 'state': '', 'visible': true, 'title': 'Gas', 'description': 'gesichert und Füllstand kontrolliert?' }, 
      { 'state': '', 'visible': true, 'title': 'Kühlschrank', 'description': 'leer und gereinigt?' }, 
      { 'state': '', 'visible': true, 'title': 'Bad', 'description': 'leer und gereinigt?' }, 
      { 'state': '', 'visible': true, 'title': 'Toilette', 'description': 'geleert und frisch befüllt' }]];
    listenset.push(checkliste['rueckkehrabfahrbereit']);

    checkliste['wintercamping'] = [{ 'state': '', 'visible': true, 'title': 'Wintercamping', 'icon': 'snow', 'path': 'folder/Wintercamping', 'info': 'Auf in den Schnee!', 'completed': 'viel Spaß!' }, 
    [
      { 'state': '', 'visible': true, 'title': 'Reinigung', 'description': 'Innen und aussen reinigen' },
      { 'state': '', 'visible': true, 'title': 'Leiter', 'description': '' },
      { 'state': '', 'visible': true, 'title': 'Schneeketten', 'description': '' },
      { 'state': '', 'visible': true, 'title': 'Besen', 'description': '' },
      { 'state': '', 'visible': true, 'title': 'Wischwasser', 'description': 'gefüllt mit Frostschutz?' },
      { 'state': '', 'visible': true, 'title': 'Reifen', 'description': 'Winterreifen montiert, Druck korrekt? Evtl. etwas erhöhen' },
      { 'state': '', 'visible': true, 'title': 'Isoliermaterial', 'description': 'eingepackt und kontrolliert' }]];
    listenset.push(checkliste['wintercamping']);

    checkliste['winter'] = [{ 'state': '', 'visible': true, 'title': 'Winterschlaf', 'icon': 'snow', 'path': 'folder/Winterschlaf', 'info': 'Der Winter kommt, nun braucht der Camper Pflege', 'completed': 'schlaf gut!' }, 
    [
      { 'state': '', 'visible': true, 'title': 'Reinigung', 'description': 'Innen und aussen reinigen' },
      { 'state': '', 'visible': true, 'title': 'Markise', 'description': 'trocknen und kontrollieren' },
      { 'state': '', 'visible': true, 'title': 'Landstrom', 'description': 'verbunden?' },
      { 'state': '', 'visible': true, 'title': 'Tank', 'description': 'vollgetankt?' },
      { 'state': '', 'visible': true, 'title': 'Wasser', 'description': 'geleert oder winterfest?' },
      { 'state': '', 'visible': true, 'title': 'Wischwasser', 'description': 'gefüllt mit Frostschutz?' },
      { 'state': '', 'visible': true, 'title': 'Reifen', 'description': 'Druck korrekt? Evtl. etwas erhöhen' },
      { 'state': '', 'visible': true, 'title': 'Schränke', 'description': 'ausgeräumt?' },
      { 'state': '', 'visible': true, 'title': 'Kühlschrank', 'description': 'leer und gereinigt?' },
      { 'state': '', 'visible': true, 'title': 'Bad', 'description': 'leer und gereinigt?' },
      { 'state': '', 'visible': true, 'title': 'Toilette', 'description': 'geleert und frisch befüllt?' },
      { 'state': '', 'visible': true, 'title': 'Klima', 'description': 'für trockenes Klima sorgen' },
      { 'state': '', 'visible': true, 'title': 'Hauptschalter', 'description': 'AUS' }]];
    listenset.push(checkliste['winter']);

    checkliste['kueche'] = [{ 'state': '', 'visible': true, 'title': 'Küche', 'icon': 'restaurant', 'path': 'folder/Küche', 'info': 'Das gehört alles in die Küche...mindestens', 'completed': 'viel Spaß beim kochen!' }, [{ 'state': '', 'visible': true, 'title': 'Spülmittel', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Spülschwamm', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Spül-/Wischlappen', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Putztücher (Mikrofaser)', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Geschirr', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Besteck', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Töpfe', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Pfanne', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Kanne', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Küchenmesser', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Kessel', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Küchenrolle', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Müllbeutel, Mülleimer', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Nudelsieb', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Gläser/Becher', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Kelle', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Kaffeemaschine', 'description': '' }]];
    listenset.push(checkliste['kueche']);

    checkliste['bad'] = [{ 'state': '', 'visible': true, 'title': 'Bad', 'icon': 'water', 'path': 'folder/Bad', 'info': 'Im Bad braucht man so dies und das. Das ist unser Vorschlag...und noch mehr!', 'completed': 'wellness kann starten!' }, [{ 'state': '', 'visible': true, 'title': 'Toilettenpapier', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Toilettenchemie', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Medikamente', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Handtücher', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Persönliche Artikel', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Duschgel', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Sonnenschutz', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Hand-Waschmittel', 'description': '' }
    ]];
    listenset.push(checkliste['bad']);

    checkliste['basis'] = [{ 'state': '', 'visible': true, 'title': 'Basisausstattung', 'icon': 'car', 'path': 'folder/Basisausstattung', 'info': 'Diese Utensilien müssen sein!', 'completed': 'jetzt kann es losgehen!' }, [{ 'state': '', 'visible': true, 'title': 'Wasserschlauch', 'description': '' }, { 'state': '', 'visible': true, 'title': 'Stromkabel', 'description': '' }, { 'state': '', 'visible': true, 'title': 'Gasflaschen', 'description': '' }, { 'state': '', 'visible': true, 'title': 'Auffahrkeile', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Besen/Kehrblech', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Werkzeug', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Gummi-/Werkstatthandschuhe', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Feuerzeug', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Klebeband', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Stühle', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Tisch', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Bettsachen', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Ladekabel (Handy, etc.)', 'description': '' }
    ]];
    listenset.push(checkliste['basis']);


    checkliste['werkzeug'] = [{ 'state': '', 'visible': true, 'title': 'Werkzeug', 'icon': 'build', 'path': 'folder/Werkzeug', 'info': 'Irgendwas geht immer mal kaputt...', 'completed': 'ran ans Werk!' }, [
      { 'state': '', 'visible': true, 'title': 'Schraubendreher', 'description': 'Kreuz, Schlitz, Elektro' }, 
      { 'state': '', 'visible': true, 'title': 'Kneifzange', 'description': '' }, 
      { 'state': '', 'visible': true, 'title': 'Rohrzange', 'description': '' }, 
      { 'state': '', 'visible': true, 'title': 'WD40', 'description': '' },
      { 'state': '', 'visible': true, 'title': 'Imbus-Set', 'description': '' },
      { 'state': '', 'visible': true, 'title': 'Stromprüfer', 'description': '12V und 230V sollte es schon beherrschen' },
      { 'state': '', 'visible': true, 'title': 'Klebeband', 'description': '' }
    ]];
    listenset.push(checkliste['werkzeug']);

    /*

    checkliste['mylist'] = [{ 'state': '', 'visible': true, 'title': 'Meine Liste', 'icon': 'person', 'path': 'folder/Mylist', 'info': 'Meine individuelle Liste', 'completed': 'super, erledigt' }, [
      { 'state': '', 'visible': true, 'title': 'Meine Punkte1', 'description': '1hier einfügen und diesen ausblenden' },
      { 'state': '', 'visible': true, 'title': 'Meine Punkte2', 'description': '2hier einfügen und diesen ausblenden' }  
    ]];
    listenset.push(checkliste['mylist']);
*/

    checkliste['menues'] = [{ 'state': '', 'visible': true, 'path': '', 'title': '', 'icon': '', 'info': '', 'completed': '' }, []];
    listenset.push(checkliste['menues']);

    //checkliste['menues'] = [{ 'state': '', 'visible': false, 'path': 'favorites', 'title': 'Plätze', 'icon': 'map', 'info': '', 'completed': '' }, []];
    //listenset.push(checkliste['menues']);
    checkliste['menues'] = [{ 'state': '', 'visible': true, 'path': 'measures', 'title': 'Abmessungen', 'icon': 'assets/kleiner_wagen.png', 'info': '', 'completed': '' }, []];
    listenset.push(checkliste['menues']);
    checkliste['menues'] = [{ 'state': '', 'visible': true, 'path': 'w-a-b', 'title': 'Gewicht', 'icon': 'barbell', 'info': '', 'completed': '' }, []];
    listenset.push(checkliste['menues']);
    checkliste['menues'] = [{ 'badge': 'warning', 'state': '', 'visible': true, 'path': 'profil', 'title': 'Profil', 'icon': 'settings', 'info': '', 'completed': '' }, []];
    listenset.push(checkliste['menues']);
    

    checkliste['menues'] = [{ 'state': '', 'visible': true, 'path': '', 'title': '', 'icon': '', 'info': '', 'completed': '' }, []];
    listenset.push(checkliste['menues']);
    checkliste['menues'] = [{ 'state': '', 'visible': true, 'path': 'information', 'title': 'Information', 'icon': 'information', 'info': '', 'completed': '' }, []];
    listenset.push(checkliste['menues']);
    checkliste['menues'] = [{ 'state': '', 'visible': true, 'path': 'help', 'title': 'Hilfe', 'icon': 'help', 'info': '', 'completed': '' }, []];
    listenset.push(checkliste['menues']);

    let l = { 'listenname': 'camper', 'listen': listenset };
    this.fillSidebar(listenset)
    alleChecklisten.push(JSON.stringify(l))

    checkliste = null;
    checkliste = [];
    listenset = null;
    listenset = [];

    checkliste['aquila'] = [{ 'state': '', 'visible': true, 'title': 'Flugzeug', 'icon': 'play-circle', 'path': 'folder/Flugzeug', 'info': 'Der Camper als technisches Gerät muß bestimmten Anforderungen genügen! Alles gecheckt?', 'completed': 'prima, fahrbereit!' },
    [{ 'state': '', 'visible': true, 'title': 'Aussencheck', 'description': 'Prüftermin in der Zukunft?' },
    { 'state': '', 'visible': true, 'title': 'Vor dem Anlassen', 'description': 'Prüftermin in der Zukunft?' },
    { 'state': '', 'visible': true, 'title': 'Anlassen', 'description': 'Geprüft' }
    ]];

    listenset.push(checkliste['aquila']);
    l = { 'listenname': 'fliegen', 'listen': listenset };
    alleChecklisten.push(JSON.stringify(l));

    checkliste = null;
    checkliste = [];
    listenset = null;
    listenset = [];

    checkliste['bauphase'] = [{ 'state': '', 'visible': true, 'title': 'Bauphase', 'icon': 'house', 'path': 'folder/Haus', 'info': 'Alle Phasen des Hausbaus', 'completed': 'prima, einziehen!!' },
    [{ 'state': '', 'visible': true, 'title': 'Vermessungsauftrag an Heinen & Fischer', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Architektengespräch', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Zeichnung der Vorabzüge', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Zeichnen des Bauantrages', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Abgabe Bauantrag beim zuständigen Bauamt ', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Beantragung der Versorger ', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Besuch Bemusterungszentrum ', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'bankbestätigte Abtretungserklärung an Massa ', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Baustellengespräch ', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Auftragserteilung Bodenplatte durch Massa ', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Bodenplattenbauer meldet sich ', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Baubeginn Gespräch am Grundstück ', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Prüfstatik ', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Baubeginnsanzeige an Bauamt ', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Terminvorgabe vom Bodenplattenbauer ', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Falls Erdbau in Eigenregie: Baustrom/Wasser, Dixi, Vermessung bestellen ', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Erdarbeiten', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Bodenplatte', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Bauschuttcontainer vor Stelltermin ', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Hausstelltermin / Richtfest ', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Elektrik/Netzwerk', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Lieferung Dämmung & Beplankung ', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Trockenbau / Wände beplanken/spachteln ', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Heizung ', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Treppe ', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Estrich', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Fliesen /Laminat ', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Malerarbeiten ', 'description': '' },
    { 'state': '', 'visible': true, 'title': 'Einzug', 'description': '' },
    ]];

    listenset.push(checkliste['bauphase']);
    l = { 'listenname': 'haus', 'listen': listenset };
    alleChecklisten.push(JSON.stringify(l));

    this.storage.set("checklisten" + globals.schema_version, alleChecklisten).then((_) => {
      this.storage.get("checklisten" + globals.schema_version).then((_) => {
        this.loadInitial("camper");

        this.selectedIndex = 1;

      }).catch((e) => {
        console.log(e);
      });
      
    });
  }




  fillSidebar(listen) {
    console.log('fillSidebar')

    for (let liste of listen) {

      //this.infos = liste[0]
      let entry = {
        title: liste[0].title,
        url: '/' + liste[0].path,
        icon: liste[0].icon
      }
      this.appPages.push(entry)
    }
  }


  storeCheckliste(checklistenname, checkliste) {
    console.log('storeCheckliste ' + checklistenname)

    this.storage.set(checklistenname + globals.schema_version, checkliste).then((_) => {
      console.log(checkliste)
    });
  }

}
